/*
 *	VUE INIT
 */

if (document.getElementById("app")) {

	Vue.component('calendar', {
		data: function () {
			return {
				reservedDays:         	[],
				weeks:                	[],
				firstDay:             	1,
				selectedCalendarYear: 	window.moment().year(),
				currentMonth:         	moment().startOf('month'),
				showAllMonths:        	this.allMonths,
			}
		},
		props: {
			allMonths: {
				default: false
			},
			canEdit: {
				default: false
			},
			location: {
				default: false
			},
			buttonText: {
				default: 'Toon meer'
			}
		},
		methods: {
			setPreviousPeriod() {
				this.selectedCalendarYear--;

					document.getElementById('availability').scrollIntoView({bahavior:'smooth'});

				this.buildCalendar();
			},
			setNextPeriod() {
				this.selectedCalendarYear++;
				document.getElementById('availability').scrollIntoView({bahavior:'smooth'});
				this.buildCalendar();
			},
			isCurrentMonth(day) {
				return day.isCurrentMonth;
			},
			getMonths() {
				if( this.showAllMonths ){
					if (parseInt(moment().format("Y")) == this.selectedCalendarYear){
						let arr = []
						for (let i = 0; i <= 11; ++i) {
							if(i >= parseInt(moment().format("M"))-1){
								arr.push(i)
							}
						}
						return arr
					}
					return [0,1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
				}
				var month = parseInt(moment().format("M"));
				return [month-1, month, month+1, month+2];
			},
			getWeekdays() {
				return [1, 2, 3, 4, 5, 6, 7];
			},
			getMonthViewStartDate(date, firstDay) {
				firstDay = parseInt(firstDay);

				let start        = moment(date);
				let startOfMonth = moment(start.startOf('month'));

				start.subtract(startOfMonth.day(), 'days');

				if (startOfMonth.day() < firstDay) {
					start.subtract(7, 'days');
				}

				start.add(firstDay, 'days');

				return start;
			},
			getWeeks(month) {
				const self = this;

				let momentMonth = moment(moment()
					.year(self.selectedCalendarYear)
					.month(month))
					.startOf('month');

				let monthMomentObject  = self.getMonthViewStartDate(momentMonth, self.firstDay);
				let weeks              = [], week = [];
				let daysInCurrentMonth = momentMonth.daysInMonth();

				for (let weekIndex = 0; weekIndex < 5; weekIndex++) {
					week = [];

					for (let dayIndex = 1; dayIndex <= 7; dayIndex++) {
						week.push(self.getDayObject(momentMonth, monthMomentObject, dayIndex));

						monthMomentObject.add(1, 'day');
					}

					weeks.push(week);
				}

				let diff = daysInCurrentMonth - weeks[4][6].date.format('D');

				if (diff > 0 && diff < 3) {
					week = [];

					for (let dayIndex = 1; dayIndex <= 7; dayIndex++) {
						week.push(self.getDayObject(momentMonth, monthMomentObject, dayIndex));

						monthMomentObject.add(1, 'day');
					}

					weeks.push(week);
				}

				return weeks;
			},
			getDayObject(momentMonth, monthMomentObject, dayIndex) {
				return {
					isToday:        monthMomentObject.isSame(moment(), 'day'),
					isPast:         monthMomentObject.isBefore(moment(), 'day'),
					isCurrentMonth: monthMomentObject.isSame(momentMonth, 'month'),
					weekDay:        dayIndex,
					date:           moment(monthMomentObject),
				};
			},
			buildCalendar() {
				const self = this;

				self.weeks = [];

				self.getMonths().forEach((month) => {
					self.weeks[month] = self.getWeeks(month);
				});
				this.getOccupiedDays()
			},
			getOccupiedDays(){
				axios.get('/calendar/reserved', {
					params: {
						year: this.selectedCalendarYear,
						location: this.location,
						showAll: this.showAllMonths,
					}
				})
				.then(res => {
					this.reservedDays = res.data;
				})
				.catch();
			},
			showAll() {
				this.showAllMonths = true;
			}
		},
		watch: {
			showAllMonths: function (val) {
				this.buildCalendar();
			}
		},
		template:
		`
		<div class="row calendar">
			<div class="col-12 text-center mb-3" v-if="showAllMonths">
				<div class="row align-items-center justify-content-center">
					<a v-if="canGoBack" href="javascript:;" class="btn btn-sm btn-outline-primary year-btn"
							@click="setPreviousPeriod()">
						<i class="icon-angle-left"></i>
					</a>

					<a v-else class="btn btn-sm btn-outline-primary year-btn disabled">
						<i class="icon-angle-left"></i>
					</a>

					<span class="bigtitle">
						{{ selectedCalendarYear }}
					</span>

					<a href="javascript:;" class="btn btn-sm btn-outline-primary year-btn"
							@click="setNextPeriod()">
						<i class="icon-angle-right"></i>
					</a>
				</div>
			</div>

			<div class="col-12">
				<div class="row justify-content-around">
					<div class="col-calendar" v-for="( month , key ) in getMonths()" :class="{'show-month-4' : !showAllMonths && key == 3 }">
						<div class="month">
							{{ month | momentCalendarMonth(selectedCalendarYear) }}
						</div>

						<div class="row justify-content-between no-gutters">
							<div class="day-name" v-for="weekDay in getWeekdays()">
								{{ weekDay | momentCalendarMonthWeek }}
							</div>
						</div>

						<div class="row justify-content-between no-gutters" v-for="(week, number) in weeks[month]">
							<div v-for="day in week" class="day"
								:class="{'invisible' : !isCurrentMonth(day), 'can-edit' : canEdit }"
								:ref="'day-' + day.date.dayOfYear()">

								<div class=""
									 :class="{
											'today': day.isToday,
											'past': (!canEdit && day.isPast),
											'reserved' : ( (canEdit || !day.isPast) && reservedDays.hasOwnProperty(day.date.format('YYYY-MM-DD')) && reservedDays[day.date.format('YYYY-MM-DD')]=='Y' ),
											'half-reserved' : ( (canEdit || !day.isPast) && reservedDays.hasOwnProperty(day.date.format('YYYY-MM-DD')) && reservedDays[day.date.format('YYYY-MM-DD')]=='H' ),
											'last-half-reserved' : ( (canEdit || !day.isPast) && reservedDays.hasOwnProperty(day.date.format('YYYY-MM-DD')) && reservedDays[day.date.format('YYYY-MM-DD')]=='LH' )
											}">

									{{ day.date | momentCalendarWeekDay }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 text-center">
				<a v-if="!showAllMonths" href="javascript:;" @click="showAll()" class="btn btn-outline-primary">{{ buttonText }}</a>
			</div>
			<div class="col-12 text-center mb-3" v-if="showAllMonths">
				<div class="row align-items-center justify-content-center">
					<a v-if="canGoBack" href="javascript:;" class="btn btn-sm btn-outline-primary year-btn"
					   @click="setPreviousPeriod()">
						<i class="icon-angle-left"></i>
					</a>

					<a v-else class="btn btn-sm btn-outline-primary year-btn disabled">
						<i class="icon-angle-left"></i>
					</a>

					<span class="bigtitle">
						{{ selectedCalendarYear }}
					</span>

					<a href="javascript:;" class="btn btn-sm btn-outline-primary year-btn"
					   @click="setNextPeriod()">
						<i class="icon-angle-right"></i>
					</a>
				</div>
			</div>
		</div>
		`,
		created(){
			window.moment.locale(document.documentElement.lang);
			this.buildCalendar();
		},
		computed:{
			canGoBack(){
				return ( this.canEdit || this.selectedCalendarYear > moment().format('YYYY'));
			},
		},
		filters:    {
			momentCalendarWeekDay:   function (date) {
				return window.moment(date, 'DD-MM-YYYY').format('DD');
			},
			momentCalendarMonth:     function (month, year) {
				if(month > 11)
					return window.moment().month(month).year(year+1).format('MMMM YYYY');
				return window.moment().month(month).year(year).format('MMMM YYYY');
			},
			momentCalendarMonthWeek: function (weekDay) {
				return window.moment().isoWeekday(weekDay).format('dd');
			}
		}
	});


	var app = new Vue({
		el: '#app'
	});
}
